import moment from 'moment'
import { page_change, set_message } from '../redux/ActionCreators'
import Store from '../redux/Store'

import fileDownload from 'js-file-download'
import { api_instance } from './Base_Api'

export const get_users = async (
    type,
    isPaging,
    selectedSubscription = '',
    advanceSearchFormData,
    onlyAdvanceSearch,
    currentSubcriptionEvent,
    leveling
) => {
    const {
        search,
        pagination: { page },
        sort,
    } = Store.getState().GeneralReducer
    try {
        const { data } = await api_instance.post('/admin/users', {
            selectedSubscription: selectedSubscription,
            selectedSubscriptionEventType: currentSubcriptionEvent,
            type: type,
            search: search,
            page: isPaging === false ? 1 : page,
            limit: 100,
            sort: sort.name,
            order: sort.mode,
            advanceSearchFormData,
            onlyAdvanceSearch,
            levelingFilter: leveling,
        })
        data.data.users.map(
            (item) => (item.birthDate = moment().diff(item.birthDate, 'years'))
        )

        Store.dispatch(page_change(data.data.pagination))

        return data
    } catch (error) {}
}

export const get_matchmakeme_form_data = async () => {
    try {
        const { data } = await api_instance.get(
            '/admin/users/matchmakeme-search-data'
        )
        return data?.data
    } catch (e) {
        console.log(e.message)
    }
}

export const submit_advance_search_data = async (payload) => {
    try {
        const { data } = await api_instance.post(
            '/admin/users/matchmakeme-search-result',
            payload
        )
        return data
    } catch (e) {
        console.log(e.message)
    }
}

export const submit_new_user = async (payload, cb) => {
    try {
        const cloneUserPayload = JSON.parse(JSON.stringify(payload))
        cloneUserPayload.userData.options.isShowInstagram = false
        cloneUserPayload.userData.ethnicityDescription = null
        cloneUserPayload.userData.industryDescription = null
        cloneUserPayload.userData.admin.isIdShow =
            cloneUserPayload.userData.admin.isIdShow ?? false
        const { data } = await api_instance.post(
            '/admin/users/add-user',
            cloneUserPayload
        )
        // console.log('show_error', data?.data?.error)
        if (data?.data?.error) {
            Store.dispatch(
                set_message({
                    mode: true,
                    message: data?.data?.error.slice(0, 250),
                    color: 'error',
                })
            )
        } else if (!data?.data?.error) {
            Store.dispatch(
                set_message({
                    mode: true,
                    message: 'User data updated successfully.',
                    color: 'success',
                })
            )
            cb()
        }
        return data
    } catch (e) {
        console.log(e.message)
    }
}

export const get_matchmakeme_users_list = async (userId, pageNumber) => {
    const {
        search,
        pagination: { page },
        sort,
    } = Store.getState().GeneralReducer
    try {
        const { data } = await api_instance.get(
            `/admin/users/matchmakeme-users-list?id=${userId}&page=${pageNumber}&limit=${20}`
        )
        console.log('debugg', data.data)
        data.data?.data?.matchedUsers?.map(
            (item) =>
                (item.birthDate = moment().diff(item.dateOfBirth, 'years'))
        )
        return data
    } catch (error) {
        return error
    }
}
export const get_matchmakeme_users = async (type, isPaging) => {
    const {
        search,
        pagination: { page },
        sort,
    } = Store.getState().GeneralReducer
    try {
        const { data } = await api_instance.get('/admin/users/matchmakeme', {
            params: {
                type: type,
                search: search,
                page: isPaging === false ? 1 : page,
                limit: 100,
                sort: sort.name,
                order: sort.mode,
            },
        })
        data.data.users.map(
            (item) => (item.birthDate = moment().diff(item.birthDate, 'years'))
        )

        Store.dispatch(page_change(data.data.pagination))

        return data
    } catch (error) {}
}

export const block_user = async (values) => {
    try {
        const { data } = await api_instance.put('/admin/users/block', values)

        Store.dispatch(
            set_message({
                mode: true,
                message: data.data.message,
                color: 'success',
            })
        )

        return data
    } catch (error) {}
}
export const unBlock_user = async (values) => {
    try {
        const { data } = await api_instance.put('/admin/users/unblock', values)

        Store.dispatch(
            set_message({
                mode: true,
                message: data.data.message,
                color: 'success',
            })
        )

        return data
    } catch (error) {}
}
export const export_users = async (
    tabValue,
    type,
    advanceSearchFormData,
    subscription,
    onlyAdvanceSearch
) => {
    try {
        const { search, sort } = Store.getState().GeneralReducer
        const { data } = await api_instance.post(
            '/admin/users/export',
            {
                type: tabValue,
                subscription,
                sort: sort.name ? sort.name : 'createdAt',
                order: sort.mode,
                search: search,
                advanceSearchFormData,
                onlyAdvanceSearch,
                format: type,
            },
            {
                responseType: 'blob',
            }
        )
        fileDownload(
            data,
            `REDDI-Users-${new Date().toDateString()}.${type.toLowerCase()}`
        )
    } catch (error) {}
}
export const update_user = async (id, values) => {
    try {
        const { data } = await api_instance.put(
            `/admin/users/update/${id}`,
            values
        )
        return data
    } catch (error) {}
}

export const delete_user = async (id, isForceDelete) => {
    try {
        let userDeleteBody = {
            isForceDelete: isForceDelete ? true : false,
        }
        const { data } = await api_instance.delete(
            '/admin/users/delete-user/' + id,
            { data: userDeleteBody }
        )

        Store.dispatch(
            set_message({
                mode: true,
                message: data.data.message,
                color: 'success',
            })
        )

        return data
    } catch (error) {}
}

export const userImageUpload = async (formData, userId) => {
    try {
        const { data } = await api_instance.post(
            `/admin/users/image?user_id=${userId} `,
            formData
        )
        Store.dispatch(
            set_message({
                mode: true,
                message: 'Image Uploaded Successfully.',
                color: 'success',
            })
        )

        return data
    } catch (error) {}
}

export const deleteUserImageApi = async (imageId, userId) => {
    try {
        const { data } = await api_instance.delete(
            `/admin/users/image/${imageId}?user_id=${userId} `
        )
        Store.dispatch(
            set_message({
                mode: true,
                message: 'Image Deleted Successfully.',
                color: 'success',
            })
        )
        return data
    } catch (error) {}
}
export const makeProfileUserImageApi = async (imageId, userId) => {
    try {
        const { data } = await api_instance.put(
            `/admin/users/image/${imageId}/default?user_id=${userId}`
        )
        Store.dispatch(
            set_message({
                mode: true,
                message: 'Success.',
                color: 'success',
            })
        )
        return data
    } catch (error) {}
}

export const get_user_all_matching_info = async (userId, reqType, page) => {
    try {
        const { data } = await api_instance.post(
            `/admin/users/user-all-matching-info`,
            {
                userId,
                reqType,
                page: page,
                limit: 10,
            }
        )
        console.log('debugg', data.data)
        data.data?.reqQueryData?.data?.map(
            (item) =>
                (item.birthDate = moment().diff(item.dateOfBirth, 'years'))
        )
        return data
    } catch (error) {
        return error
    }
}

export const get_recommended_users_list = async (userId, page, search) => {
    try {
        const { data } = await api_instance.post(
            `/admin/users/recommended-users-list`,
            {
                page: page,
                limit: 10,
                _id: userId,
                search: search,
            }
        )
        console.log('debugg', data.data)
        data.data?.reqQueryData?.data?.map(
            (item) =>
                (item.birthDate = moment().diff(item.dateOfBirth, 'years'))
        )
        return data
    } catch (error) {
        return error
    }
}

export const update_recommended_user = async (userId, users, isStatusTrue) => {
    try {
        const { data } = await api_instance.post(
            `/admin/users/update-recommended-user`,
            {
                userId: userId,
                recommendedUsers: users,
                status: isStatusTrue,
            }
        )
        return data
    } catch (error) {
        return error
    }
}
export const downloadUserProfileApi = async (userId, fileName) => {
    try {
        const response = await api_instance.get(
            `/admin/users/download-user-profile?userId=${userId}`,
            {
                responseType: 'blob',
            }
        )
        const blob = response.data
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
    } catch (error) {
        return error
    }
}

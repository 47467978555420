import { TabPanelContainer } from '../../components/TabPanel'
import { styles } from '../../styles/styles'
import {
    Button,
    Dialog,
    Divider,
    Fade,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core'
import DeleteUser from '../ManageUsers/UserDelete'
import DownloadUserProfile from '../ManageUsers/downloadUserProfile'
import { forwardRef, useEffect, useState } from 'react'
import { Close } from '@material-ui/icons'
import Overview from './viewUserProfile/Overview'
import MoreInfo from './viewUserProfile/MoreInfo'
import Photos from './viewUserProfile/Photos'
import EditInfo from '../../asset/img/Edit.png'
import { get_match_make_me_questionnair } from '../../api/UserRequest_Api'
import MatchMakeMeIcon from '../../asset/img/matchMakeMeIcon.png'
import { MatchMakeMeButton } from '../ManageUsers/matchMakeMeButton'
import AttachmentStyleReport from './viewUserProfile/AttachmentStyleReport'
const ViewUserProfile = ({
    data,
    setDeleteMutation,
    set_data = {},
    dropdowns,
}) => {
    console.log('debueeee', data)
    const [modalClickAble, setModalClickable] = useState('auto')
    const [open, setOpen] = useState(false)
    const [tab_index, set_tab_index] = useState(1)
    const [matchMakeMeQuestions, setMatchMakeMeQuestions] = useState([])
    const classes = styles()

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    useEffect(() => {
        if (open) {
            get_match_make_me_questionnair(data?._id)
                ?.then((res) => {
                    console.log('res', res.data?.questions)
                    setMatchMakeMeQuestions(res.data?.questions)
                })
                .catch((err) => {
                    console.log('errr_get_match_make_me_questionnair', err)
                })
        }
    }, [open, data?._id])
    return (
        <>
            <Button
                onClick={() => handleOpen()}
                // disabled={status}
                variant="outlined"
                style={{ padding: '5px 15px', borderRadius: 6 }}
            >
                <img
                    src={MatchMakeMeIcon}
                    alt="Match Make Me Icon"
                    width={20}
                    height={20}
                    style={{ marginRight: 5 }}
                />
                User Profile
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                style={{ pointerEvents: modalClickAble }}
                onClick={(e) => e.stopPropagation()}
                classes={{
                    paper: classes.viewProfileDialog,
                }}
            >
                <Grid
                    xs={12}
                    container
                    item
                    spacing={0}
                    style={{}}
                    justifyContent="center"
                >
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ padding: 16 }}
                    >
                        <Typography variant="h4">Profile View</Typography>

                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                    {/* ------------------------------------------------------------------------------------ header section */}
                    <Grid
                        item
                        xs={12}
                        container
                        spacing={2}
                        alignItems="center"
                        style={{ margin: 'auto', padding: 16 }}
                    >
                        {menus.map((item) => (
                            <Button
                                key={item.index}
                                // hidden={isUser && item.title === "Reported Messages"}
                                style={{
                                    marginRight: 16,
                                    borderRadius: 12,
                                    height: 44,
                                    width: item.index !== 4 ? 96 : 200,
                                    backgroundColor:
                                        tab_index !== item.index
                                            ? '#F4F5F5'
                                            : '#65819D',
                                    color:
                                        tab_index !== item.index
                                            ? '#000'
                                            : '#fff',
                                }}
                                variant={
                                    tab_index === item.index
                                        ? 'contained'
                                        : 'text'
                                }
                                onClick={() => set_tab_index(item.index)}
                            >
                                {item.title}
                            </Button>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {/* ------------------------------------------------------------------------------------ pages section */}
                    <Grid
                        item
                        xs={12}
                        style={{ padding: tab_index !== 4 ? 16 : 0 }}
                    >
                        {data ? (
                            <>
                                <TabPanelContainer value={tab_index} index={1}>
                                    <Overview data={{ user: data }} />
                                </TabPanelContainer>
                                <TabPanelContainer value={tab_index} index={2}>
                                    <MoreInfo data={{ user: data }} />
                                </TabPanelContainer>
                                <TabPanelContainer value={tab_index} index={3}>
                                    <Photos data={data?.images} />
                                </TabPanelContainer>
                                <TabPanelContainer value={tab_index} index={4}>
                                    <AttachmentStyleReport
                                        attachment_style_report={
                                            data?.attachment_style_report
                                        }
                                    />
                                </TabPanelContainer>
                            </>
                        ) : null}

                        {/* {isUser === false && ( */}
                        {/* <TabPanelContainer value={tab_index} index={4}>
              <ReportedMessage data={isUser ? data : data?.messagesReports} />
            </TabPanelContainer> */}
                        {/* )} */}
                    </Grid>
                    {/* ------------------------------------------------------------------------------------ footer section */}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ padding: 16 }}
                    >
                        {/* {!isUser && (
                            <Grid item>
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        clear_reports({ ids: [data._id] }).then(
                                            (res) => {
                                                if (!res) return
                                                set_data((pre) =>
                                                    pre.filter(
                                                        (item) =>
                                                            item._id !==
                                                            data._id
                                                    )
                                                )
                                                setOpen(false)
                                            }
                                        )
                                    }}
                                >
                                    Clear Report
                                </Button>
                            </Grid>
                        )} */}
                        <Grid item>
                            {data?._id ? (
                                <Button
                                    onClick={() => {
                                        const win = window.open(
                                            `/user-information/${data?._id}`,
                                            '_blank'
                                        )

                                        win.focus()
                                    }}
                                    variant="outlined"
                                    style={{
                                        padding: '10px 15px',
                                        borderRadius: 6,
                                    }}
                                >
                                    <img
                                        width={15}
                                        height={15}
                                        src={EditInfo}
                                        alt=""
                                        style={{ marginRight: 6 }}
                                    />
                                </Button>
                            ) : null}
                        </Grid>
                        {data?.status === 'deleted'
                            ? null
                            : data?.deletedAtTime == null && (
                                  <Grid item>
                                      {data?._id ? (
                                          <DeleteUser
                                              data={data}
                                              userName={`${data?.firstName} ${data?.lastName}`}
                                              set_Data={set_data}
                                              status={
                                                  data?.status === 'deleted'
                                              }
                                              setDeleteMutation={
                                                  setDeleteMutation
                                              }
                                              handleCloseOuter={handleClose}
                                          />
                                      ) : null}
                                  </Grid>
                              )}

                        <Grid item>
                            {data?._id ? (
                                <DownloadUserProfile
                                    setModalClickable={setModalClickable}
                                    modalClickable={modalClickAble}
                                    zodiacDropdownOptions={
                                        dropdowns?.zodiacDropdownOptions
                                    }
                                    sleepingHabbitsDropdownOptions={
                                        dropdowns?.sleepingHabbitsDropdownOptions
                                    }
                                    drinkingDropdownOptions={
                                        dropdowns?.drinkingDropdownOptions
                                    }
                                    smokingDropdownOptions={
                                        dropdowns?.smokingDropdownOptions
                                    }
                                    relocateForLoveDropdownOptions={
                                        dropdowns?.relocateForLoveDropdownOptions
                                    }
                                    personalityDropdownValues={
                                        dropdowns?.personalityDropdownValues
                                    }
                                    industryDropdownOptions={
                                        dropdowns?.industryDropdownOptions
                                    }
                                    educationDropdownOptions={
                                        dropdowns?.educationDropdownOptions
                                    }
                                    salaryDropdownOptions={
                                        dropdowns?.salaryDropdownOptions
                                    }
                                    lookingForDropdownOptions={
                                        dropdowns?.lookingForDropdownOptions
                                    }
                                    matchMakeMeQuestions={matchMakeMeQuestions}
                                    data={data}
                                    userName={`${data?.firstName} ${data?.lastName}`}
                                    set_Data={set_data}
                                    status={data?.status === 'deleted'}
                                    setDeleteMutation={setDeleteMutation}
                                    handleCloseOuter={handleClose}
                                />
                            ) : null}
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                style={{ borderRadius: 6, padding: '7px 15px' }}
                                color="default"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}

export default ViewUserProfile
const Transition = forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})

const menus = [
    { title: 'Overview', index: 1 },
    { title: 'More Info', index: 2 },
    { title: 'Photos', index: 3 },
    { title: 'Attachment Style Report', index: 4 },
    // { title: "Reported Messages", index: 4 },
]
